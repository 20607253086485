<template>
  <v-card
    class="pa-3"
    :class="{ 'elevation-12': $vuetify.breakpoint.smAndUp }"
    flat
    width="100%"
    max-width="320px"
  >
    <v-alert class="message-wrapper error-message mb-2" icon="$alert">
      <p>
        {{ $t("404message").format(url) }}
      </p>
    </v-alert>
    <v-card-actions>
      <v-btn color="primary" @click="$router.go(-1)">{{ $t("back") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  title: "pageNotFound",
  name: "PageNotFound",
  computed: {
    url() {
      return this.$route.fullPath;
    },
  },
};
</script>
